@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #0077be;
  --secondary-color: #f4a460;
  --accent-color: #32cd32;
  --text-color: #333;
  --background-color: #f0f8ff;
  --card-background: rgba(255, 255, 255, 0.9);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  color: var(--text-color);
  background-color: var(--background-color);
}

.lake-chelan-experience {
  overflow-x: hidden;
}

/* Navbar Styles */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}

.navbar.visible {
  transform: translateY(0);
}

.nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.nav-content h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
}

/* Header Styles */
.hero {
  /* background-image: url('../../../public/img/lakechelan.jpg'); */
  background-image: url('../../../public/img/back.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
 
  z-index: 1;
}


.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}

.hero-content {
  position: relative;
  z-index: 1;
  max-width: 800px;
  /* padding: 2rem; */
}

.hero h1 {
  font-size: 4rem;
  font-weight: 700;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

/* Button Styles */
.btn-primary, .btn-secondary, .btn-tertiary {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 50px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
}

.btn-tertiary {
  background-color: transparent;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.btn-primary:hover, .btn-secondary:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn-tertiary:hover {
  background-color: var(--primary-color);
  color: white;
}

/* Section Styles */
/* section {
  padding: 4rem 0;
} */

h2 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
}

h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: var(--secondary-color);
  margin: 1rem auto 0;
}

.card {
  background-color: var(--card-background);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-bottom: 2rem;

  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.card-content {
  padding: 2rem;
}
.card-content p{
  line-height: 1.5;
  padding: 15px;

}
.card-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.card:hover .card-image img {
  transform: scale(1.05);
}
.activities-section{
  padding: 4rem 0;
}
.home-away-section{
  
}
/* Activities Section */
.activities-grid {
  display: grid;
 
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.activity-card {
  background-color: var(--card-background);
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.activity-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.activity-icon {
  font-size: 3rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

/* Home Away Section */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}
.card{
  padding: 30px;
}
/* Host Section */
.host-section .card {
  display: flex;
  flex-direction: column;
}

.host-image {
  flex: 1;
  min-height: 300px;
  overflow: hidden;
}

.host-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.host-section .card:hover .host-image img {
  transform: scale(1.05);
}

.host-content {
  flex: 1;
  padding: 2rem;
}

/* CTA Section */
.cta-section .card {
  background-color: var(--primary-color);
  color: white;
  text-align: center;
  padding: 3rem;
}

.cta-section h2 {
  color: white;
}

.cta-section h2::after {
  background-color: white;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: white;
  padding: 3rem 0 1rem;
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.footer-section h3 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: var(--secondary-color);
}

.footer-section ul {
  list-style-type: none;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: var(--secondary-color);
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

/* Scroll to Top Button */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.scroll-to-top:hover {
  background-color: var(--secondary-color);
  transform: translateY(-3px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.2rem;
  }

  section {
    padding: 4rem 0;
  }

  .card-grid {
    grid-template-columns: 1fr;
  }

  .host-section .card {
    flex-direction: column;
  }

  .host-image {
    min-height: 200px;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 2.5rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .btn-primary, .btn-secondary, .btn-tertiary {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }

  h2 {
    font-size: 2rem;
  }
}

main {
  position: relative;
  z-index: 2;
  background-color: var(--background-color);
}

.experience-section {
  /* padding: 5rem 0; */
   /* Ensure the section below has enough space */

  z-index: 0;
}

.experience-section .card{
  /* padding: 2rem; */
}
.adjustment{
  padding: 0 3rem ;
}