@keyframes vibrate {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

.animate-vibrate {
  animation: vibrate 0.3s infinite alternate;
}
