/* For webkit-based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #d6d8e7;
}

::-webkit-scrollbar-thumb {
  background: #3b8cd7;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2661a8;
}

@font-face {
  font-family: 'MyCustom';
  src:
    url('../public/font/poppins.otf') format('opentype'),
    url('../public/font/poppins.ttf') format('truetype');

  /* Add additional font formats if necessary */
  /* Ensure the path to the font files is correct */
}
